import {
  GATSBY_WEBSITE_URL,
  GATSBY_FIREBASE_USER_ROLE_ID,
} from "gatsby-env-variables"
import firebase, { auth } from "firebase"
import { isBrowser } from "../../../../../services/general"

export const createAddressDocument = async ({ values }) => {
  let newAddressDocument = {
    addresses: [
      {
        type: values?.deliveryAddress?.addressType,
        streetAddress: values?.deliveryAddress?.streetAddress,
        city: values?.deliveryAddress?.city?.value,
        province: values?.deliveryAddress?.province?.value,
        barangay: values?.deliveryAddress?.barangay?.value,
        primary: values?.deliveryAddress?.province?.value === "Metro Manila",
      },
    ],
  }

  return await firebase
    .firestore()
    .collection("addresses")
    .add({ ...newAddressDocument })
}

export const createUserDocument = async ({ user, values, addressesUid }) => {
  let newUserDocument = {
    authUid: user?.uid,
    firstName: values?.firstName,
    middleName: values?.middleName,
    lastName: values?.lastName,
    birthday: {
      month: values?.birthday?.month?.value,
      date: values?.birthday?.date?.value,
      year: values?.birthday?.year?.value,
    },
    mobileNumber: `+63${values?.mobileNumber}`,
    email: values?.email,
    secondaryContactName: values?.secondaryContactName,
    secondaryContactNumber: `+63${values?.secondaryContactNumber}`,
    roles: [
      {
        role: GATSBY_FIREBASE_USER_ROLE_ID,
        subdomain: GATSBY_WEBSITE_URL,
        status: "inactive",
      },
    ],
    hasUploadedValidID: true,
    noValidIDAfter12Hours: false,
    numberOfTimesHasUploadedID: 1,
  }

  if (addressesUid) newUserDocument.addresses = addressesUid

  await firebase
    .firestore()
    .collection("users")
    .add({ ...newUserDocument })
}

export const updateAddressDocument = async ({ values, addressesUid }) => {
  let updatedAddressDocument = {
    addresses: [
      {
        type: values?.deliveryAddress?.addressType,
        streetAddress: values?.deliveryAddress?.streetAddress,
        city: values?.deliveryAddress?.city?.value,
        province: values?.deliveryAddress?.province?.value,
        barangay: values?.deliveryAddress?.barangay?.value,
        primary: values?.deliveryAddress?.province?.value === "Metro Manila",
      },
    ],
  }

  await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesUid)
    .update({ ...updatedAddressDocument })

  if (isBrowser()) {
    sessionStorage.setItem(
      "addresses",
      JSON.stringify({ ...updatedAddressDocument, id: addressesUid })
    )
  }
}

export const updateUserDocument = async ({ values, user, addressesUid }) => {
  let updatedUserDocument = {
    addresses: addressesUid,
    authUid: user?.authUid,
    email: values?.email,
    firstName: values?.firstName,
    hasUploadedValidID: true,
    noValidIDAfter12Hours: false,
    lastName: values?.lastName,
    middleName: values?.middleName,
    mobileNumber: `+63${values?.mobileNumber}`,
    numberOfTimesHasUploadedID: parseInt(user.numberOfTimesHasUploadedID) + 1,
    birthday: {
      month: values?.birthday?.month?.value,
      date: values?.birthday?.date?.value,
      year: values?.birthday?.year?.value,
    },
  }

  await firebase
    .firestore()
    .collection("users")
    .doc(user?.id)
    .update({ ...updatedUserDocument })
}
