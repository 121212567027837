const printDateValue = (date) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${date.year.value}`
}

export const patientEnrollmentTicketBody = (request) => {
  let {
    firstName,
    middleName,
    lastName,
    mobileNumber,
    birthday,
    email,
    alternativeLandline, // optional
    isTransferringProgram,
    hasReportedAdverseEvents,
    heartCareProgramSerialCode, // optional
    whenWillPatientOrder,
    idType,
    deliveryAddress,
    secondaryContactName,
    secondaryContactNumber,
    doctorFullName,
    terms,
  } = request

  let formattedTerms = ""
  for (let i = 0; i < terms.length; i++) {
    formattedTerms += `${i + 1}. ${terms[i]}\n`
  }

  return `Personal Details
  First Name: ${firstName}
  Middle Name: ${middleName}
  Last Name: ${lastName}
  Mobile Number: ${mobileNumber}
  Birthday: ${printDateValue(birthday)}
  Email: ${email}
  Alternative Landline: ${alternativeLandline || "N/A"}
  You are transferring from another program: ${isTransferringProgram}
  You have reported adverse events: ${hasReportedAdverseEvents || "N/A"}
  Heart Care Patient Code: ${heartCareProgramSerialCode || "N/A"}
  You will likely order: ${whenWillPatientOrder}
  Type of ID Uploaded: ${idType.value}
  ---
  Type: ${deliveryAddress.addressType}
  Street: ${deliveryAddress.streetAddress}
  Barangay: ${deliveryAddress.barangay.value}
  City: ${deliveryAddress.city.value}
  Province: ${deliveryAddress.province.value}
  ---
  Secondary Contact Person
  Full Name: ${secondaryContactName}
  Mobile Number: ${secondaryContactNumber}
  ---
  Doctor: ${doctorFullName}
  ---
  Consent and Authorization
  ${formattedTerms}
  `
}
